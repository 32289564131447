@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}

* {
  min-height: 0;
  min-width: 0;
}

/* Button.css */
.hmsui-button-iconOnlyLg svg {
  @apply h-9 w-auto;
}

.hmsui-button-iconOnlyMd svg {
  @apply h-6 w-auto;
}

.hmsui-button-iconOnlySm svg {
  @apply h-5 w-auto;
}

.hmsui-button-rootLg svg {
  @apply h-6 w-auto;
}

.hmsui-button-rootMd svg {
  @apply h-5 w-auto;
}

.hmsui-button-rootSm svg {
  @apply h-4 w-auto;
}

/* VideoTile/index.css */
.hmsui-videoTile-root:hover .hmsui-videoTileControls-controls {
  visibility: visible;
  max-height: 60px;
}
